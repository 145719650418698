<script setup lang="ts">
import {onMounted} from "vue";
import {RouterLink, RouterView} from 'vue-router'
import FirstAnimation from "@/components/firstAnimation/FirstAnimationLogo.vue";
import Header from "@/components/header/Header.vue";
import Hamburger from "@/components/Hamburger.vue"
import ToggleMenu from "@/components/ToggleMenu.vue"
import Footer from "@/components/footer/Footer.vue"
import SetHeaderHeightToPaddingTop from "@/assets/js/setHeaderHeightToPaddingTop"

onMounted(() => {
    const setHeaderHeightToPaddingTop = new SetHeaderHeightToPaddingTop;
    setHeaderHeightToPaddingTop.init();
})


/**
 * ScrollBehaviorにイベントをさせる。
 */
const beforeEnterScrollBehavior = () => {
    // DoScrollBehaviorカスタムイベント呼び出して、ScrollBehavior処理へ。
    window.dispatchEvent(new CustomEvent('DoScrollBehavior'));
};
</script>

<template>
    <div class="body">
        <FirstAnimation/>
        <Header/>
        <Hamburger/>
        <ToggleMenu/>
        <div class="main js_setHeaderHeightToPaddingTop">
            <RouterView v-slot="{Component, route}">
                <transition
                    name="page-transition"
                    mode="out-in"
                    @before-enter="beforeEnterScrollBehavior"
                    appear
                >
                    <component :is="Component"/>
                </transition>
            </RouterView>
        </div>
        <Footer/>
    </div>
</template>

<style scoped lang="scss">
.body {
    display: flex; /* フレックスボックスに有効に */
    flex-flow: column; /* 要素を縦に並べる */
    min-height: 100vh; /* 最小でも画面の高さ分のbodyの高さを担保する */
    min-height: 100svh; /* 最小でも画面の高さ分のbodyの高さを担保する */
}

.main {
    flex: 1; /* 画面の高さから他の同レイヤーの要素の高さを引いた値に */
}


.page-transition-enter-from,
.page-transition-leave-to {
    opacity: 0;
}

.page-transition-enter-active,
.page-transition-leave-active {
    transition: opacity 500ms;
}
</style>
