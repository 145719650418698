<script setup>

</script>
<template>
    <!-- footer -->
    <footer class="footer">
        <div class="footer-inner">
            <div class="footer-unit">
                <p class="footer-heading">
                    <a href="../">長崎県建設業<span class="-wb">就職ポータルサイト</span></a>
                </p>
                <ul class="footer-exLinks">
                    <li class="footer-exLinks-item">
                        <a class="footer-exLinks-link" href="https://n-navi.pref.nagasaki.jp/" target="_blank" rel="noopener noreferrer">
                            <span class="footer-exLinks-txt">Nなび</span>
<!--                            <img class="footer-exLinks-img" src="/src/assets/img/common/ex-link_b.svg" alt="" width="12" height="12">-->
                        </a>
                    </li>
                    <li class="footer-exLinks-item">
                        <a class="footer-exLinks-link" href="https://nagasaki-iju.jp/" target="_blank" rel="noopener noreferrer">
                            <span class="footer-exLinks-txt">移住ナビ</span>
<!--                            <img class="footer-exLinks-img" src="/src/assets/img/common/ex-link_b.svg" alt="" width="12" height="12">-->
                        </a>
                    </li>
                    <li class="footer-exLinks-item">
                        <a class="footer-exLinks-link" href="https://jsite.mhlw.go.jp/nagasaki-roudoukyoku/home.html" target="_blank" rel="noopener noreferrer">
                            <span class="footer-exLinks-txt">長崎労働局</span>
<!--                            <img class="footer-exLinks-img" src="/src/assets/img/common/ex-link_b.svg" alt="" width="12" height="12">-->
                        </a>
                    </li>
                    <li class="footer-exLinks-item">
                        <a class="footer-exLinks-link" href="http://www.ngsk-kenkyou.or.jp/" target="_blank" rel="noopener noreferrer">
                            <span class="footer-exLinks-txt">長崎県建設業協会</span>
<!--                            <img class="footer-exLinks-img" src="/src/assets/img/common/ex-link_b.svg" alt="" width="12" height="12">-->
                        </a>
                    </li>
                    <li class="footer-exLinks-item">
                        <a class="footer-exLinks-link" href="https://www.pref.nagasaki.jp/" target="_blank" rel="noopener noreferrer">
                            <span class="footer-exLinks-txt">長崎県HP</span>
<!--                            <img class="footer-exLinks-img" src="/src/assets/img/common/ex-link_b.svg" alt="" width="12" height="12">-->
                        </a>
                    </li>
                </ul>
            </div>
            <div class="footer-copyright">
                <small>Copyright&copy;2022 Nagasaki Prefecture Government.</small>
            </div>
        </div>
    </footer>
    <!-- \END footer -->
</template>


<style scoped lang="scss">
@use "@/assets/scss/modules/variables" as var;
.footer {
    font-family: ZenKakuGothicNew, sans-serif;
}

.footer-inner {
    padding: 50px 30px 15px;
}

.footer-unit {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-heading {
    color: var(--blue);
    font-weight: 900;
    @media (var.$min-md) {
        font-size: 1.8rem;
    }
}

.footer-exLinks {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.footer-exLinks-item {
    position: relative;

    &:not(:last-of-type)::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: 1px;
        height: 16px;
        background-color: #000;
    }
}

.footer-exLinks-link {
    display: flex;
    align-items: center;
    padding: 10px 15px;
}

.footer-exLinks-txt {
    padding-right: 5px;
}

.footer-copyright {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
</style>
