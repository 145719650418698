import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const router = createRouter({
    // history: createWebHistory(import.meta.env.BASE_URL),
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomeView,
        },
        {
            path: '/about',
            name: 'about',
            component: () => import('../views/AboutView.vue')
        },
        {
            path: '/news',
            name: 'news',
            component: () => import('../views/NewsView.vue')
        },
        {
            path: '/news/:post_id/:preview?',
            name: 'news-single',
            component: () => import('../views/NewsSingleView.vue')
        },
        {
            path: '/contact',
            name: 'contact',
            component: () => import('../views/ContactView.vue')
        },
        {
            path: '/:pathMatch(.*)*',
            name: '404',
            component: () => import('../views/404.vue')
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        let position: {} = {};

        const header = document.getElementById('js_header');
        let headerHeight: number = 0;
        if (header) {
            const headerHeightPx = window.getComputedStyle(header).height;
            headerHeight = parseInt(headerHeightPx);
        }

        if (to.name === from.name) {
            if (to.hash) {
                position = {
                    el: to.hash,
                    // top: parseInt(window.getComputedStyle(document.getElementById('js_header')).height),
                    top: headerHeight,
                    behavior: 'smooth',
                }
            } else {
                position = {
                    top: 0,
                    behavior: 'smooth',
                }
            }
            return position
        }

        return new Promise(resolve => {
            window.addEventListener('DoScrollBehavior', () => {
                if (from.name) {
                    if (savedPosition) {
                        position = savedPosition;
                    }
                    if (to.hash) {
                        position = {
                            el: to.hash,
                            top: headerHeight,
                        }
                    }
                    resolve(position);
                }
            }, {capture: false, once: true, passive: true});

        });
    },
})

router.beforeEach((to, from): {} | undefined => {
    if (to.query.preview) {
        console.log('test')
        return {name: to.query.post_type + '-single', params: {post_id: to.query.p, preview: true}};
    }
    return;
})

export default router
