<script setup>
import {useRoute} from 'vue-router';

const route = useRoute();

</script>

<template>
    <!-- ヘッダー header -->
    <header id="js_header" class="header">
        <div class="header-inner">
            <nav class="header-heading">
                <router-link :to="{name: 'home'}">
                    株式会社CONECONE
                </router-link>
            </nav>
            <nav class="header-nav d-none d-lg-block">
                <ul class="header-nav-list d-flex fw-500">
                    <li class="header-nav-item">
                        <router-link :to="{name: 'home', hash: '#news'}">NEWS</router-link>
                    </li>
                    <li class="header-nav-item">
                        <router-link :to="{name: 'home', hash: '#interview'}">NEWS</router-link>
                    </li>
                    <li class="header-nav-item">
                        <router-link :to="{name: 'home', hash: '#movie'}">NEWS</router-link>
                    </li>
                    <li class="header-nav-item">
                        <router-link :to="{name: 'home', hash: '#link'}">NEWS</router-link>
                    </li>
                    <li class="header-nav-item">
                        <router-link :to="{name: 'home'}">お問い合わせ</router-link>
                    </li>
                </ul>
            </nav>
        </div>
    </header>
    <!-- \END ヘッダー header -->
</template>

<style lang="scss">
@use "@/assets/scss/modules/variables" as var;

.header {
    z-index: 500;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    //max-width: 1400px;
    //margin-right: auto;
    //margin-left: auto;
    background-color: #FFF;
    box-shadow: 0 0 8px rgba(0, 0, 0, .5);
    font-family: "Zen Kaku Gothic New", sans-serif;
    @media (var.$min-md) {
        //min-height: 65px;
    }
}

.header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 15px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    max-width: 1320px;
    height: 100%;
    @media (var.$min-md) {
        padding: 18px 30px;
    }
    @media (var.$min-lg) {
        padding: 24px 30px;
    }
}

.header-heading {
    font-size: 1.6rem;
    font-weight: 900;
    @media (var.$min-md) {
        font-size: 1.8rem;
    }
}

.header-nav-item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 15px;
    font-size: 1.6rem;
    letter-spacing: 0.16em;
    line-height: 1;

    &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 34px 48px 0 0;
        border-color: #ffff00 transparent transparent transparent;
        top: 50%;
        left: 50%;
        transform: rotate(70deg) translate(-40%, 60%);
        z-index: -1;
        transition: all ease 300ms;
        visibility: hidden;
        opacity: 0;
    }
    &:hover::after {
        visibility: visible;
        opacity: 1;
    }

    &:not(:last-of-type)::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: 1px;
        height: 16px;
        background-color: black;
    }
}
</style>
