import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger);


export default function ScrollTriggers() {
	
	gsap.utils.toArray('.js_fade_up').forEach(target => {
		let gsapJsFadeUp = gsap.fromTo(target, {
			opacity: 0,
			yPercent: 20,
		}, {
			opacity: 1,
			yPercent: 0,
			duration: 1,
			scrollTrigger: {
				trigger: target,
				start: "top 70%",
				once: true,
				// toggleActions: "play none none none",
				// markers: true,
			},
		});
		window.addEventListener('gsapScrollTriggerRefresh', () => {
			gsapJsFadeUp.scrollTrigger.refresh();
		});
	});
	
	gsap.utils.toArray(".js_appear").forEach(function (target) {
		gsap.from(target, {
			opacity: 0,
			scrollTrigger: {
				trigger: target,
				start: "top center+=25%",
				toggleActions: "play none none none",
				//markers: true,
				once: true,
			},
		});
	});
	
	gsap.utils.toArray('.js-parallax').forEach(target => {
		let fromY = target.dataset.from || 100;
		let toY   = target.dataset.to || -100;
		gsap.fromTo(target, {
			y: fromY,
		}, {
			y: toY,
			ease: 'none',
			scrollTrigger: {
				trigger: target,
				start: 'top bottom',
				end: 'bottom top',
				scrub: true,
			},
		})
	})
}