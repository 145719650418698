<script setup>
import {onMounted} from 'vue';
import Hamburger from '@/assets/js/hamburger.js';
onMounted(()=> {
    const h = new Hamburger();
    h.init();
})
</script>

<template>
    <!-- ハンバーガーボタン hamburger -->
    <div class="btnHamburgerWrap d-block d-lg-none">
        <button id="js_btnHamburger"
                class="btnHamburger"
                type="button"
                aria-controls="global-nav"
                aria-expanded="false"
                aria-label="メニューを開閉する">
        </button>
    </div>
    <!-- \END ハンバーガーボタン hamburger -->
</template>

<style lang="scss">
@use "src/assets/scss/modules/variables" as var;
///*  ハンバーガーボタン
// 991.98表示　992非表示
//============================== */
/**
hamburger.jsファイル用
 */
.btnHamburgerWrap {
    z-index: 1000;
    position: fixed;
    top: 13px;
    right: 15px;
    font-family: "Zen Kaku Gothic New", sans-serif;
    @media (var.$min-md) {
        top: 11px;
        right: 30px;
    }
    @media (var.$min-lg) {
        position: static;
        z-index: auto;
        right: auto;
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

.btnHamburger {
    position: relative;
    text-decoration: none;
    appearance: none;
    border: none;
    cursor: pointer;
    outline: none!important;
}

.btnHamburger_line {
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    -webkit-transition: inherit;
    transition: inherit;
    filter: drop-shadow(0 0 8px #FFF);
}

.btnHamburger_txt {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
}
</style>