<script setup lang="ts">
import {ref, defineProps, onMounted} from 'vue';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger);

const props = defineProps({
    number: {
        type: String,
        required: true,
    },
    title: {
        type: String,
        required: true,
    },
    text: {
        type: String,
        required: true,
    }

});

const number = ref(null);
const title = ref(null);
const line = ref(null);
const text = ref(null);

onMounted(() => {
    const gsapLine = gsap.to(line.value, {
        strokeDashoffset: '0px',
        duration: 0.8,
        invalidateOnRefresh: true,
        scrollTrigger: {
            trigger: number.value,
            start: "center 70%",
            once: true,
            // markers: true,
        }
    });
    const gsapNumber = gsap.to(number.value, {
        opacity: 1,
        duration: 0.8,
        delay: 1.0,
        scrollTrigger: {
            trigger: number.value,
            start: "center 70%",
            once: true,
            // markers: true,
        }
    });
    const gsapTitle = gsap.to(title.value, {
        opacity: 1,
        duration: 0.8,
        delay: 1.0,
        scrollTrigger: {
            trigger: number.value,
            start: "center 70%",
            once: true,
            // markers: true,
        },
    });
    const gsapText = gsap.to(text.value, {
        opacity: 1,
        duration: 0.8,
        delay: 1.5,
        scrollTrigger: {
            trigger: number.value,
            start: "center 70%",
            once: true,
            // markers: true,
        },
    });

    window.addEventListener("gsapScrollTriggerRefresh", () => {
        gsapLine.scrollTrigger.refresh();
        gsapNumber.scrollTrigger.refresh();
        gsapTitle.scrollTrigger.refresh();
        gsapText.scrollTrigger.refresh();
    }, 2000);
})

</script>

<template>
    <div class="cardLine">
        <h3 class="cardLine-title">
            <span ref="number" class="cardLine-title-number">{{ props.number }}</span>
            <span ref="title" class="cardLine-title-letter">{{ props.title }}</span>
        </h3>
        <svg class="cardLine-diagonalLine" xmlns="http://www.w3.org/2000/svg" width="59.688" height="56.725" viewBox="0 0 59.688 56.725">
            <line ref="line" class="cardLine-line" data-name="line" x1="59" y2="56" transform="translate(0.344 0.363)" fill="none" stroke="#1e2e40" stroke-width="1"/>
        </svg>
        <p ref="text" class="cardLine-text" v-html="props.text">
        </p>
    </div>
</template>

<style scoped lang="scss">
@use "@/assets/scss/modules/variables" as var;

.cardLine {
    position: relative;
    width: 100%;
    max-width: 300px;
    padding-top: 47px;
    padding-left: 39px;

    &:not(:first-child) {
        margin-top: 80px;
    }

    @media (var.$min-lg) {
        margin-top: 0 !important;
        margin-right: 6%;
        &:nth-of-type(3n) {
            margin-right: 0;
        }
    }
}

.cardLine-title {
    font-weight: 700;
}

.cardLine-title-number {
    position: absolute;
    top: 5px;
    left: 0;
    font-size: 2.6rem;
    opacity: 0;
}

.cardLine-title-letter {
    opacity: 0;
    font-size: 1.6rem;
}

.cardLine-text {
    opacity: 0;
    margin-top: 8px;
    line-height: 1.5;
}

.cardLine-diagonalLine {
    position: absolute;
    //top: -3px;
    top: 14px;
    left: 2px;
    //width: 1px;
    //height: 70px;
    //transform: rotateZ(45deg);
    //background-color: $main;
    transform-origin: bottom;
}

.cardLine-line {
    stroke-dasharray: 80px;
    stroke-dashoffset: 80px;
}
</style>