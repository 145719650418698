import {gsap} from 'gsap';

/**
 * @example
 * <div class="btnHamburgerWrap d-block d-lg-none">
 *     <button id="js_btnHamburger"
 *			   class="btnHamburger"
 *     		   type="button"
 *     		   aria-controls="global-nav"
 *     		   aria-expanded="false"
 *     		   aria-label="メニューを開閉する">
 *     </button>
 * </div>
 */
export default class Hamburger {
	constructor() {
		// ブレイクポイント
		this.breakPoint        = 768;
		// ハンバーガーボタンの幅
		this.btnWidth          = 32;
		this.btnWidhtMd        = 42;
		// ハンバーガーボタンの高さ
		this.btnHeight         = 24;
		this.btnHeightMd       = 32;
		// ハンバーガーアイコンの線の幅
		this.lineWidthOffset   = 0;
		this.lineWidthOffsetMd = 0;
		// ハンバーガーアイコンの線の高さ
		this.lineHeight        = 3;
		this.lineHeightMd      = 4;
		// 閉じている時のハンバーガーアイコンの線の色
		this.lineCloseColor    = '#000';
		// 開いている時の時のハンバーガーアイコンの線の色
		this.lineOpenColor     = '#000';
		
		this.duration        = 0.3;
		this.btn             = document.getElementById('js_btnHamburger');
		this.lines           = [];
		this.lineTop         = document.createElement('span');
		this.lineMid         = document.createElement('span');
		this.lineBtm         = document.createElement('span');
		this.toggleMenu      = document.getElementById('js_toggleMenu');
		this.closeFlag       = 1;
		this.fireFlag        = 0;
		this.closeEveHandler = '';
		this.openEveHandler  = '';
	}
	
	init() {
		if (this.btn && this.toggleMenu) {
			// ボタンの３本の線を配列にまとめる
			this.lines = [this.lineTop, this.lineMid, this.lineBtm];
			// 3本線に基本スタイルを適応
			this.lines.forEach(line => {
				line.style.backgroundColor = this.lineCloseColor;
				line.classList.add('btnHamburger_line');
				this.btn.appendChild(line);
			});
			
			this.adjustStyleForBtnWidth();
			this.adjustStyleForBtnHeight();
			this.adjustStyleForLineTop();
			this.adjustStyleForLineWidth();
			this.adjustStyleForLineHeight();
			this.closeEveHandler = this.closeEvent.bind(this);
			this.openEveHandler  = this.openEvent.bind(this);
			// リサイズのイベント
			window.addEventListener('resize', this.closeEveHandler, false);
			// ハンバーガーアイコン　クリックイベント監視
			this.btn.addEventListener('click', this.click.bind(this));
			window.addEventListener('clickHamburgerBtn', this.click.bind(this), false);
		}
	}
	
	/**
	 * ハンバーガーボタンの幅のスタイルを調整する（height）
	 */
	adjustStyleForBtnWidth() {
		if (window.innerWidth < this.breakPoint) {
			this.btn.style.width = this.btnWidth + 'px';
		} else {
			this.btn.style.width = this.btnWidhtMd + 'px';
		}
	}
	
	/**
	 * ハンバーガーボタンの（高さ）のスタイルを調整する（height）
	 */
	adjustStyleForBtnHeight() {
		if (window.innerWidth < this.breakPoint) {
			this.btn.style.height = this.btnHeight + 'px';
		} else {
			this.btn.style.height = this.btnHeightMd + 'px';
		}
	}
	
	/**
	 * ハンバーガーボタンの線の横幅のスタイルを調整する（width）
	 */
	adjustStyleForLineWidth() {
		if (window.innerWidth < this.breakPoint) {
			this.lines.forEach(line => {
				line.style.width = this.btnWidth - this.lineWidthOffset + 'px';
			});
		} else {
			this.lines.forEach(line => {
				line.style.width = this.btnWidhtMd - this.lineWidthOffsetMd + 'px';
			});
		}
	}
	
	/**
	 * ハンバーガーボタンの線の高さのスタイルを調整する（height）
	 */
	adjustStyleForLineHeight() {
		if (window.innerWidth < this.breakPoint) {
			this.lines.forEach(line => {
				line.style.height = this.lineHeight + 'px';
			});
		} else {
			this.lines.forEach(line => {
				line.style.height = this.lineHeightMd + 'px';
			});
		}
	}
	
	/**
	 * ハンバーガーボタンの線と線の間隔のスタイルを調整する（top）
	 */
	adjustStyleForLineTop() {
		if (window.innerWidth < this.breakPoint) {
			if (this.closeFlag) {
				this.lineTop.style.top = 0 + 'px';
				this.lineMid.style.top = (this.btnHeight - this.lineHeight) / 2 + 'px';
				this.lineBtm.style.top = (this.btnHeight - this.lineHeight) + 'px';
			} else {
				this.lines.forEach(line => {
					line.style.top = (this.btnHeight - this.lineHeight) / 2 + 'px';
				})
			}
		} else {
			if (this.closeFlag) {
				this.lineTop.style.top = 0 + 'px';
				this.lineMid.style.top = (this.btnHeightMd - this.lineHeightMd) / 2 + 'px';
				this.lineBtm.style.top = (this.btnHeightMd - this.lineHeightMd) + 'px';
			} else {
				this.lines.forEach(line => {
					line.style.top = (this.btnHeightMd - this.lineHeightMd) / 2 + 'px';
				})
			}
		}
	}
	
	setFlagOpen() {
		this.closeFlag = 0;
	}
	
	setFlagClose() {
		this.closeFlag = 1;
	}
	
	/**
	 * このメソッドを使用して、クリックイベントを発生させる事が出来る
	 */
	click() {
		if (this.closeFlag) {
			this.open();
		} else {
			this.close();
		}
	}
	
	/**
	 * ボタンが閉じている時に、リサイズした時のイベント内容
	 */
	closeEvent() {
		let timer = false;
		if (!timer) clearTimeout(timer);
		timer = setTimeout(() => {
			this.adjustStyleForBtnWidth();
			this.adjustStyleForBtnHeight();
			this.adjustStyleForLineTop();
			this.adjustStyleForLineWidth();
			this.adjustStyleForLineHeight();
		}, 100);
	}
	
	/**
	 * ボタンが開いている時に、リサイズした時のイベント内容
	 */
	openEvent() {
		let timer = false;
		if (!timer) clearTimeout(timer);
		timer = setTimeout(() => {
			this.adjustStyleForBtnWidth();
			this.adjustStyleForBtnHeight();
			this.adjustStyleForLineWidth();
			this.adjustStyleForLineHeight();
			this.adjustStyleForLineTop();
			// this.lineTop.style.top = window.getComputedStyle(this.lineMid).top;
			// this.lineBtm.style.top = window.getComputedStyle(this.lineMid).top;
		}, 100);
	}
	
	/**
	 * ボタンを開ける時の、アニメーションの内容
	 */
	open() {
		if (this.fireFlag) {
			Promise.resolve()
				.then(() => {
					return new Promise(resolve => {
						this.changeFlagOpen();
						return resolve();
					})
				})
				.then(() => {
					this.click();
				})
		} else {
			this.fireFlag = 1;
			// this.changeFlagOpen();
			window.removeEventListener('resize', this.closeEveHandler, false);
			window.addEventListener('resize', this.openEveHandler, false);
			// ライン(上)へのアニメーション
			gsap.to(this.lineTop, {
				rotateZ: '30deg',
				top: window.getComputedStyle(this.lineMid).top,
				backgroundColor: this.lineOpenColor,
				duration: this.duration
			});
			// ライン(中)へのアニメーション
			gsap.to(this.lineMid, {
				opacity: 0,
				duration: this.duration,
			})
			// ライン(下)へのアニメーション
			gsap.to(this.lineBtm, {
				rotateZ: '-30deg',
				top: window.getComputedStyle(this.lineMid).top,
				backgroundColor: this.lineOpenColor,
				duration: this.duration,
			});
			// トグルメニューのアニメーション
			gsap.to(this.toggleMenu, {
				opacity: 1,
				height: '100vh',
				duration: this.duration,
				onComplete: this.changeFlagOpen.bind(this),
			});
		}
	}
	
	/**
	 * ボタンを閉じる時の、アニメーションの内容
	 */
	close() {
		if (this.fireFlag) {
			Promise.resolve()
				.then(() => {
					return new Promise(resolve => {
						this.changeFlagClose();
						return resolve();
					})
				})
				.then(() => {
					this.click();
				});
		} else {
			this.fireFlag = 1;
			// this.changeFlagClose();
			window.removeEventListener('resize', this.openEveHandler, false);
			window.addEventListener('resize', this.closeEveHandler, false);
			// ライン(上)へのアニメーション
			gsap.to(this.lineTop, {
				rotateZ: 0,
				top: 0,
				backgroundColor: this.lineCloseColor,
				duration: this.duration
			});
			// ライン(中)へのアニメーション
			// Velocity(this.lineMid, {opacity: 1}, {duration: this.duration});
			gsap.to(this.lineMid, {
				opacity: 1,
				duration: this.duration,
			});
			// ライン(下)へのアニメーション
			gsap.to(this.lineBtm, {
				rotateZ: 0,
				top: parseInt(window.getComputedStyle(this.lineMid).top) * 2 + 'px',
				backgroundColor: this.lineCloseColor,
				duration: this.duration,
			});
			// トグルメニューのアニメーション
			gsap.to(this.toggleMenu, {
				opacity: 0,
				height: 0,
				duration: this.duration,
				onComplete: this.changeFlagClose.bind(this),
			});
		}
	}
	
	changeFlagOpen() {
		this.fireFlag = 0;
		this.setFlagOpen();
	}
	
	changeFlagClose() {
		this.fireFlag = 0;
		this.setFlagClose();
		// this.toggleMenu.style.opacity = 0;
	}
}

// export let hamburger = '';
// window.addEventListener("DOMContentLoaded", function () {
// 	hamburger = new Hamburger();
// 	hamburger.init();
// }, false);
	
