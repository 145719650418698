<script setup>

/**
 * ハンバーガーボタンクリックイベントを発火させるためのカスタムイベントを登録
 */
const onLinkClick = () => {
    // カスタムイベント発行 イベント先-> Hamburger.vue
    window.dispatchEvent(new CustomEvent('clickHamburgerBtn'));
};

</script>

<template>
<!-- トグルメニュー ToggleMenu -->
    <nav class="toggleMenu d-block d-lg-none" id="js_toggleMenu">
        <div class="toggleMenu-inner">
            <nav class="toggleMenu-nav">
                <ul class="toggleMenu-nav-list">
                    <li class="toggleMenu-nav-item">
                        <router-link :to="{name: 'home', hash: '#message'}"
                                     @click="onLinkClick"
                                     class="js_scrollNav toggleMenu-nav-link"
                        >
                            メッセージ
                        </router-link>
                    </li>
                    <li class="toggleMenu-nav-item">
                        <router-link :to="{name: 'home', hash: '#interview'}"
                                     @click="onLinkClick"
                                     class="js_scrollNav toggleMenu-nav-link"
                        >
                            特集記事
                        </router-link>
                    </li>
                    <li class="toggleMenu-nav-item">
                        <router-link :to="{name: 'home', hash: '#movie'}"
                                     @click="onLinkClick"
                                     class="js_scrollNav toggleMenu-nav-link"
                        >
                            PR動画
                        </router-link>
                    </li>
                    <li class="toggleMenu-nav-item">
                        <router-link :to="{name: 'home', hash: '#link'}"
                                     @click=onLinkClick
                                     class="js_scrollNav toggleMenu-nav-link"
                        >
                            リンク
                        </router-link>
                    </li>
                    <li class="toggleMenu-nav-item">
                        <router-link :to="{name: 'home'}"
                                     @click="onLinkClick"
                                     class="toggleMenu-nav-link"
                        >
                            長崎県の建設業
                        </router-link>
                    </li>
                </ul>
<!--                <figure class="toggleMenu-landmarkWrap">-->
<!--                    <img class="toggleMenu-landmark" src="/src/assets/img/common/landmark_sp.svg" alt="企業・移住情報はこちら">-->
<!--                </figure>-->
<!--                <ul class="toggleMenu-exLinks">-->
<!--                    <li class="toggleMenu-exLinks-item">-->
<!--                        <a class="toggleMenu-exLinks-link" href="https://n-navi.pref.nagasaki.jp/" target="_blank" rel="noopener noreferrer">-->
<!--                            <img src="/src/assets/img/common/n-navi_icon.svg" class="icon1" alt="nなび">-->
<!--                            <img src="/src/assets/img/common/ex-link_b.svg" class="ex" alt="">-->
<!--                        </a>-->
<!--                    </li>-->
<!--                    <li class="toggleMenu-exLinks-item">-->
<!--                        <a class="toggleMenu-exLinks-link" href="https://nagasaki-iju.jp/" target="_blank" rel="noopener noreferrer">-->
<!--                            <img src="/src/assets/img/common/ijyu-s_icon.svg" class="icon2" alt="ながさき移住ナビ">-->
<!--                            <img src="/src/assets/img/common/ex-link_b.svg" class="ex" alt="">-->
<!--                        </a>-->
<!--                    </li>-->
<!--                </ul>-->
            </nav>
        </div>
    </nav>
<!-- \END トグルメニュー ToggleMenu -->
</template>

<style scoped lang="scss">
.toggleMenu {
    z-index: 900;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 0;
    background: #FFF;
    opacity: 0;
    overflow: scroll;
}

.toggleMenu-inner {
    display: flex;
    justify-content: center;
    padding: 120px 15px;
    //height: 100%;
}

.toggleMenu-nav {
    width: 260px;
    font-size: 1.6rem;
    font-weight: 500;
}

.toggleMenu-nav-item {
    border-bottom: solid 1px #000;
}

.toggleMenu-nav-link {
    display: block;
    padding: 18px 3px;
}

.toggleMenu-landmarkWrap {
    margin-top: 30px;
}

.toggleMenu-exLinks {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.toggleMenu-exLinks-item {
    position: relative;
    display: flex;
    justify-content: center;
    border: solid 1px #000;
    margin-top: 10px;
    padding: 13px;
    width: 240px;
}

.toggleMenu-exLinks-link {
    display: flex;
    align-items: center;

    .ex {
        position: absolute;
        top: 50%;
        right: 13px;
        transform: translateY(-50%);
    }
}
</style>