/**
 * 要素の高さを指定する。（画面の高さから、ヘディングのパディングトップの高さを引いた値を、要素の高さに指定する）
 *
 * <header id="js_header">
 *
 * <div class="js_setWindowInnerHeight-HeaderHeight">
 *
 */
export default class SetWindowInnerHeightMinusHeaderHeight {
	constructor() {
		this.header     = null;
		this.targetElms = null;
		this.timer      = false;
	}
	
	init() {
		Promise.resolve()
			.then(() => {
				return new Promise(resolve => {
					// 必要な要素を取得
					this.header     = document.getElementById('js_header');
					this.targetElms = Array.from(document.getElementsByClassName('js_setWindowInnerHeightMinusHeaderHeight'));
					return resolve();
				});
			})
			.then(() => {
				if (this.header && this.targetElms) {
					this.adjustEvent();
					window.addEventListener('resize', () => {
						if (this.timer != false) clearTimeout(this.timer);
						this.timer = setTimeout(() => {
							this.adjustEvent();
						}, 50);
					}, false);
				}
			})
	}
	
	adjustEvent() {
		this.targetElms.forEach((targetElm, idx) => {
			// targetElm.style.height = (window.innerHeight - this.header.clientHeight) + 'px';
			targetElm.style.height = (document.documentElement.clientHeight - this.header.clientHeight) + 'px';
			targetElm.style.width = '100%';
		});
	}
}

// document.addEventListener('DOMContentLoaded', () => {
// 	let setHeaderHeightToPaddingTop = new SetHeaderHeightToPaddingTop();
// 	setHeaderHeightToPaddingTop.init();
// }, false);