/**
 * ヘッダーの高さをパディングトップに指定する。
 *
 * <header id="js_header">
 *
 * <div class="js_setHeaderHeightToPaddingTop">
 *
 */
export default class SetHeaderHeightToPaddingTop {
	constructor() {
		this.header     = null;
		this.targetElms = null;
		this.timer      = false;
	}
	
	init() {
		Promise.resolve()
			.then(() => {
				return new Promise(resolve => {
					// 必要な要素を取得
					this.header     = document.getElementById('js_header');
					this.targetElms = Array.prototype.slice.call(document.getElementsByClassName('js_setHeaderHeightToPaddingTop'));
					return resolve();
				});
			})
			.then(() => {
				if (this.header) {
					this.adjustEvent();
					window.addEventListener('resize', () => {
						if (this.timer != false) clearTimeout(this.timer);
						this.timer = setTimeout(() => {
							this.adjustEvent();
						}, 50);
					}, false);
				}
			})
	}
	
	adjustEvent() {
		this.targetElms.forEach((targetElm, idx) => {
			console.log('ヘッダーの高さ: ', this.header.clientHeight);
			targetElm.style.paddingTop = this.header.clientHeight + 'px';
		});
	}
}

// document.addEventListener('DOMContentLoaded', () => {
// 	let setHeaderHeightToPaddingTop = new SetHeaderHeightToPaddingTop();
// 	setHeaderHeightToPaddingTop.init();
// }, false);