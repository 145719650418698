<script setup lang="ts">
import {onMounted, ref, defineProps} from "vue";
import {gsap} from "gsap";
import {ScrollTrigger} from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger);

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    titleEnglish: {
        type: String,
        required: true,
    },
})

const line = ref(null);
const heading = ref(null);
onMounted(() => {
    const gsapLine = gsap.fromTo(line.value, {
        yPercent: -100,
    }, {
        yPercent: 100,
        duration: 0.8,
        scrollTrigger: {
            trigger: heading.value,
            start: "top 70%",
            // toggleActions: "play none none none",
            // markers: true,
            once: true,
        },
        onComplete: () => {
            gsap.fromTo(line.value, {
                yPercent: -100,
            }, {
                yPercent: 0,
                duration: 0.8,
            })
        }
    });

    const gsapHeading = gsap.from(heading.value, {
        opacity: 0,
        yPercent: 20,
        delay: 0.8,
        duration: 0.8,
        scrollTrigger: {
            trigger: heading.value,
            start: "top 70%",
            // toggleActions: "play none none none",
            once: true,
        }
    });

    window.addEventListener('gsapScrollTriggerRefresh', () => {
        gsapLine.scrollTrigger.refresh();
        gsapHeading.scrollTrigger.refresh();
    })
})
</script>

<template>
    <div class="js_inview_heading verticalLineBox">
        <span ref="line" class="verticalLine"></span>
    </div>
    <h2 ref="heading" class="headingA" :title="props.titleEnglish">
        {{props.title}}
    </h2>
</template>

<style scoped lang="scss">
@use "@/assets/scss/modules/variables" as var;

.verticalLineBox {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1px;
    height: 60px;
    overflow: hidden;
    @media (var.$min-md) {
        height: 100px;
    }
    @media (var.$min-lg) {
        height: 140px;
    }

    &.el_verticalLineBox__static {
        position: relative;
        transform: translate(-50%, 0);
    }

    &.el_verticalLineBox__long {
        height: 100px;
    }
}

.verticalLine {
    display: block;
    width: 100%;
    height: 100%;
    background-color: var.$text-color;
}

.headingA {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 0;
    font-weight: 700;
    letter-spacing: .7px;
    font-size: 1.3rem;
    font-family: NotoSerifJP, serif;
    @media (var.$min-md) {
        padding: 100px 0;
    }

    &::before {
        content: attr(title);
        margin-bottom: 6px;
        font-size: 2.6rem;
        @media (var.$min-md) {
            font-size: 3.6rem;
        }
    }
}

</style>