<script setup lang="ts">
import {onMounted, onUpdated, reactive} from "vue";
import {RouterLink, useRouter} from "vue-router";
import HeadingA from "@/components/heading/HeadingA.vue";
import CardLine from "@/components/card/CardLine.vue";

const router = useRouter();


/**
 *
 */
import SetWindowInnerHeightMinusHeaderHeight from "@/assets/js/setWindowInnerHeightMinusHeaderHeight";

const setWindowInnerHeightMinusHeaderHeight = new SetWindowInnerHeightMinusHeaderHeight();
onMounted(() => {
    setWindowInnerHeightMinusHeaderHeight.init();
})


/**
 * scroll triggers
 */
import ScrollTriggers from "@/assets/js/scrollTriggers";

onMounted(() => {
    ScrollTriggers();
})

onUpdated(()=> {
    window.dispatchEvent(new CustomEvent('gsapScrollTriggerRefresh'));
})

/**
 * Swiper
 */
import {Swiper, SwiperSlide} from 'swiper/vue';
import {Pagination, Autoplay, EffectFade, Zoom} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/effect-fade";

const swiperOptionsA = {
    modules: [Autoplay, EffectFade, Pagination],
    speed: 1400,//スライドの切り替わりスピード
    loop: true, //無限ループ
    autoplay: { //スライドの自動切り替え
        delay: 6000,//スライドの自動切り替えの秒数
        disableOnInteraction: false//何らかのアクション後の自動切り替えを再開
    },
    pagination: {
        el: '.jumbotron_pagination',
        clickable: true,
    },
    effect: 'fade',
    // spaceBetween: 30,//各スライドの余白
    // centeredSlides: true,//スライダーを真ん中に
    // breakpoints: {
    //     1200: {
    //         slidesPerView: 1.4
    //     },
    //     768: {
    //         slidesPerView: 1.2,
    //         // spaceBetween: 0
    //     }
    // },
    // slidesPerView: 1,
};

/**
 * WPの記事を３つ取得。
 */
const article = reactive({
    perPage: 3,
    data: [],
});

const getArticle = () => {
    fetch('/wp-json/wp/v2/news?context=embed&per_page=' + article.perPage, {
        method: 'GET',
    })
        .then(response => {
            if (response.ok) {
                // console.log('総記事数', response.headers.get('x-wp-total'));
                // console.log('総ページ数', response.headers.get('x-wp-totalpages'));
                // article.total = Number(response.headers.get('x-wp-total'));
                // article.totalPages = Number(response.headers.get('x-wp-totalpages'));
                return response.json();
            }
        })
        .then(result => {
            article.data = [];
            // console.log('レスポンス', result);
            result.forEach((resultObj: object, idx: number) => {
                // console.log(obj);
                // let resultInit = [];
                let year = resultObj.date.slice(0, 4);
                let month = resultObj.date.slice(5, 7);
                let day = resultObj.date.slice(8, 10);
                let articleDataInit: {
                    id: number,
                    title: string,
                    year: string,
                    month: string,
                    day: string,
                } = {
                    id: resultObj.id,
                    title: resultObj.title.rendered,
                    year,
                    month,
                    day,
                };
                article.data.push(articleDataInit);
            })
            console.log('レスポンス加工後', article.data);
        })
}
getArticle();

</script>

<template>
    <main>
        <div class="jumbotron js_setWindowInnerHeightMinusHeaderHeight">
            <div class="jumbotron-inner">
                <!--            <swiper class="jumbotron-inner swiper-container"-->
                <!--                    :modules="[Autoplay, EffectFade, Pagination, Zoom]"-->
                <!--                    :loop="swiperOptionsA.loop"-->
                <!--                    :speed="1400"-->
                <!--                    :autoplay="swiperOptionsA.autoplay"-->
                <!--                    :effect="swiperOptionsA.effect"-->
                <!--                    :pagination="swiperOptionsA.pagination"-->
                <!--            >-->
                <!--                <swiper-slide>-->
                <!--                    <picture>-->
                <!--                        <source srcset="@/assets/images/childline-nagasaki-jumbotron-bg1@2x.webp" type="image/webp">-->
                <!--                        <source srcset="@/assets/images/childline-nagasaki-jumbotron-bg1.jpg" type="image/jpeg">-->
                <!--                        <img class="jumbotron-img"-->
                <!--                             src="@/assets/images/childline-nagasaki-jumbotron-bg1.jpg"-->
                <!--                             alt="チャイルドライン長崎（child line nagasaki）のヒーローイメージ"-->
                <!--                        >-->
                <!--                    </picture>-->
                <!--                </swiper-slide>-->
                <!--                <swiper-slide>-->
                <!--                    <picture>-->
                <!--                        <source srcset="@/assets/images/childline-nagasaki-jumbotron-bg2@2x.webp" type="image/webp">-->
                <!--                        <source srcset="@/assets/images/childline-nagasaki-jumbotron-bg2.jpg" type="image/jpeg">-->
                <!--                        <img class="jumbotron-img"-->
                <!--                             src="@/assets/images/childline-nagasaki-jumbotron-bg2.jpg"-->
                <!--                             alt="チャイルドライン長崎（child line nagasaki）のヒーローイメージ"-->
                <!--                        >-->
                <!--                    </picture>-->
                <!--                </swiper-slide>-->
                <!--                &lt;!&ndash;        <div class="swiper-button-prev"></div>&ndash;&gt;-->
                <!--                &lt;!&ndash;        <div class="swiper-button-next"></div>&ndash;&gt;-->
                <!--                <div class="jumbotron_pagination swiper-pagination"></div>-->
                <!--            </swiper>-->
                <video class="jumbotron-video" src="@/assets/videos/cone_cone_hero_video.mp4" playsinline autoplay muted loop></video>
                <h1 class="jumbotron-logo">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 301.34 44.85">
                        <g>
                            <path class="firstAnimation-text" d="M27.71,31.3c-2.52,3.55-4.97,6.15-7.34,7.78-2.38,1.63-5.01,2.45-7.9,2.45-3.18,0-5.77-1.12-7.78-3.35s-3.01-5.12-3.01-8.68.97-7.5,2.91-11.34,4.43-7.02,7.47-9.54c3.04-2.52,5.96-3.78,8.77-3.78,1.9,0,3.43.53,4.59,1.58,1.16,1.05,1.74,2.47,1.74,4.25,0,1.61-.61,3.15-1.83,4.62-1.22,1.47-2.53,2.2-3.94,2.2-.62,0-1.18-.25-1.67-.74,2.69-1.2,4.03-3.43,4.03-6.69,0-1.16-.3-2.05-.9-2.7-.6-.64-1.39-.96-2.39-.96-1.53,0-3.07.62-4.62,1.86s-3.01,2.97-4.37,5.18c-1.36,2.21-2.43,4.57-3.19,7.07-.76,2.5-1.15,5.03-1.15,7.59,0,3.06.72,5.54,2.17,7.44,1.45,1.9,3.35,2.85,5.7,2.85,2.02,0,3.99-.64,5.89-1.92,1.9-1.28,3.88-3.24,5.95-5.89l.87.74Z"/>
                            <path class="firstAnimation-text" d="M31.99,28.7c0-3.72,1-7.43,3.01-11.13,2-3.7,4.6-6.7,7.78-9.02,3.18-2.31,6.42-3.47,9.73-3.47,3.47,0,6.27,1.15,8.4,3.44,2.13,2.29,3.19,5.26,3.19,8.89,0,3.97-.98,7.81-2.94,11.53-1.96,3.72-4.55,6.77-7.75,9.14-3.2,2.38-6.44,3.56-9.7,3.56-3.51,0-6.34-1.19-8.49-3.56-2.15-2.38-3.22-5.51-3.22-9.39ZM58.51,15.07c0-2.48-.61-4.52-1.83-6.14-1.22-1.61-2.78-2.42-4.68-2.42-1.74,0-3.4.56-4.99,1.67-1.59,1.12-3.05,2.75-4.37,4.9-1.49,2.48-2.72,5.37-3.69,8.68-.97,3.31-1.46,6.24-1.46,8.8,0,3.06.6,5.41,1.8,7.07,1.2,1.65,2.91,2.48,5.14,2.48,3.59,0,6.76-2.5,9.48-7.5,1.4-2.56,2.52-5.43,3.35-8.61.83-3.18,1.24-6.16,1.24-8.92Z"/>
                            <path class="firstAnimation-text" d="M69.79,41.22l6.57-34.34,5.89-2.54-5.33,22.75c3.43-7.23,6.84-12.73,10.23-16.49,3.39-3.76,6.63-5.64,9.73-5.64,1.53,0,2.77.51,3.72,1.52.95,1.01,1.43,2.37,1.43,4.06,0,.95-.23,2.29-.68,4.03l-.62,2.6-1.86,7.56-.62,2.42c-.62,2.48-.93,4.38-.93,5.7s.23,2.39.68,3.19c.45.81,1.05,1.21,1.8,1.21,1.28,0,3.33-1.53,6.14-4.59l.99.87-.62.68c-2.36,2.77-4.09,4.64-5.21,5.61s-2.15,1.46-3.1,1.46c-1.28,0-2.37-.65-3.25-1.95-.89-1.3-1.33-2.92-1.33-4.87,0-1.32.25-3.28.74-5.89l2.42-13.14c.33-2.07.5-3.51.5-4.34,0-.87-.23-1.53-.68-1.98-.45-.45-1.07-.68-1.86-.68-2.85,0-6.36,3.06-10.54,9.17-2.02,2.93-3.7,5.84-5.02,8.71-1.32,2.87-2.81,6.85-4.46,11.93l-.56,1.67-4.15,1.3Z"/>
                            <path class="firstAnimation-text" d="M135.68,30.99l1.12.93c-2.56,3.39-5.06,5.84-7.5,7.34-2.44,1.51-5.06,2.26-7.87,2.26-3.35,0-6.03-1.12-8.06-3.35-2.02-2.23-3.04-5.19-3.04-8.86s.98-7.49,2.94-11.31c1.96-3.82,4.42-6.98,7.38-9.48,2.95-2.5,5.88-3.75,8.77-3.75,1.69,0,3.09.5,4.18,1.49,1.09.99,1.64,2.27,1.64,3.84,0,3.18-1.74,6.36-5.21,9.54-3.47,3.18-8.2,5.89-14.19,8.12-.04.33-.06.7-.06,1.12,0,2.85.78,5.18,2.36,6.97,1.57,1.8,3.59,2.7,6.07,2.7,1.94,0,3.82-.61,5.64-1.83,1.82-1.22,3.76-3.13,5.83-5.73ZM115.85,26.28c4.46-1.98,7.89-4.19,10.29-6.63,1.61-1.65,2.91-3.43,3.9-5.33.99-1.9,1.49-3.62,1.49-5.14,0-1.94-.89-2.91-2.67-2.91-2.69,0-5.4,2.12-8.15,6.35-2.75,4.24-4.37,8.79-4.87,13.67Z"/>
                            <path class="firstAnimation-text" d="M189.11,31.3c-2.52,3.55-4.97,6.15-7.34,7.78-2.38,1.63-5.01,2.45-7.9,2.45-3.18,0-5.78-1.12-7.78-3.35-2-2.23-3.01-5.12-3.01-8.68s.97-7.5,2.91-11.34c1.94-3.84,4.43-7.02,7.47-9.54,3.04-2.52,5.96-3.78,8.77-3.78,1.9,0,3.43.53,4.59,1.58,1.16,1.05,1.74,2.47,1.74,4.25,0,1.61-.61,3.15-1.83,4.62-1.22,1.47-2.53,2.2-3.94,2.2-.62,0-1.18-.25-1.67-.74,2.69-1.2,4.03-3.43,4.03-6.69,0-1.16-.3-2.05-.9-2.7-.6-.64-1.39-.96-2.39-.96-1.53,0-3.07.62-4.62,1.86s-3.01,2.97-4.37,5.18c-1.36,2.21-2.43,4.57-3.19,7.07-.77,2.5-1.15,5.03-1.15,7.59,0,3.06.72,5.54,2.17,7.44,1.45,1.9,3.35,2.85,5.7,2.85,2.02,0,3.99-.64,5.89-1.92,1.9-1.28,3.88-3.24,5.95-5.89l.87.74Z"/>
                            <path class="firstAnimation-text" d="M193.38,28.7c0-3.72,1-7.43,3.01-11.13,2-3.7,4.6-6.7,7.78-9.02,3.18-2.31,6.42-3.47,9.73-3.47,3.47,0,6.27,1.15,8.4,3.44,2.13,2.29,3.19,5.26,3.19,8.89,0,3.97-.98,7.81-2.94,11.53-1.96,3.72-4.55,6.77-7.75,9.14-3.2,2.38-6.44,3.56-9.7,3.56-3.51,0-6.34-1.19-8.49-3.56s-3.22-5.51-3.22-9.39ZM219.91,15.07c0-2.48-.61-4.52-1.83-6.14s-2.78-2.42-4.68-2.42c-1.74,0-3.4.56-4.99,1.67-1.59,1.12-3.05,2.75-4.37,4.9-1.49,2.48-2.72,5.37-3.69,8.68-.97,3.31-1.46,6.24-1.46,8.8,0,3.06.6,5.41,1.8,7.07,1.2,1.65,2.91,2.48,5.14,2.48,3.59,0,6.76-2.5,9.48-7.5,1.4-2.56,2.52-5.43,3.35-8.61.83-3.18,1.24-6.16,1.24-8.92Z"/>
                            <path class="firstAnimation-text" d="M231.19,41.22l6.57-34.34,5.89-2.54-5.33,22.75c3.43-7.23,6.84-12.73,10.23-16.49,3.39-3.76,6.63-5.64,9.73-5.64,1.53,0,2.77.51,3.72,1.52.95,1.01,1.43,2.37,1.43,4.06,0,.95-.23,2.29-.68,4.03l-.62,2.6-1.86,7.56-.62,2.42c-.62,2.48-.93,4.38-.93,5.7s.23,2.39.68,3.19c.45.81,1.05,1.21,1.8,1.21,1.28,0,3.33-1.53,6.14-4.59l.99.87-.62.68c-2.36,2.77-4.09,4.64-5.21,5.61s-2.15,1.46-3.1,1.46c-1.28,0-2.37-.65-3.25-1.95-.89-1.3-1.33-2.92-1.33-4.87,0-1.32.25-3.28.74-5.89l2.42-13.14c.33-2.07.5-3.51.5-4.34,0-.87-.23-1.53-.68-1.98-.46-.45-1.08-.68-1.86-.68-2.85,0-6.36,3.06-10.54,9.17-2.03,2.93-3.7,5.84-5.02,8.71-1.32,2.87-2.81,6.85-4.46,11.93l-.56,1.67-4.15,1.3Z"/>
                            <path class="firstAnimation-text" d="M297.08,30.99l1.12.93c-2.56,3.39-5.06,5.84-7.5,7.34-2.44,1.51-5.06,2.26-7.87,2.26-3.35,0-6.03-1.12-8.06-3.35-2.02-2.23-3.04-5.19-3.04-8.86s.98-7.49,2.94-11.31c1.96-3.82,4.42-6.98,7.38-9.48,2.95-2.5,5.88-3.75,8.77-3.75,1.69,0,3.09.5,4.18,1.49,1.09.99,1.64,2.27,1.64,3.84,0,3.18-1.74,6.36-5.21,9.54-3.47,3.18-8.2,5.89-14.19,8.12-.04.33-.06.7-.06,1.12,0,2.85.78,5.18,2.36,6.97,1.57,1.8,3.59,2.7,6.07,2.7,1.94,0,3.82-.61,5.64-1.83,1.82-1.22,3.76-3.13,5.83-5.73ZM277.24,26.28c4.46-1.98,7.89-4.19,10.29-6.63,1.61-1.65,2.91-3.43,3.91-5.33.99-1.9,1.49-3.62,1.49-5.14,0-1.94-.89-2.91-2.66-2.91-2.69,0-5.4,2.12-8.15,6.35-2.75,4.24-4.37,8.79-4.87,13.67Z"/>
                        </g>
                    </svg>
                </h1>
            </div>
        </div>

        <!-- NEWSページ -->
        <div id="news" class="homeNews position-relative">
            <div class="homeNews-inner container">
                <HeadingA title="お知らせ" title-english="NEWS"/>
                <ul class="homeNews-list js_fade_up">
                    <li v-for="data in article.data" class="homeNews-item">
                        <RouterLink :to="{name: 'news-single', params: {post_id: data.id}}"
                                    class="homeNews-link"
                        >
                            <time class="homeNews-date">
                                {{ data.year }}.{{ data.month }}.{{ data.day }}
                            </time>
                            <p class="homeNews-item-title">{{ data.title }}</p>
                        </RouterLink>
                    </li>
                </ul>
            </div>
        </div>
        <div id="" class="homeService position-relative pb-100">
            <div class="homeService-inner container">
                <HeadingA title="サービス" title-english="SERVICES"/>
                <div class="cardService">
                    <CardLine number="1"
                              title="WEBマーケティング"
                              text="私たちは、インターネットを使って良いサービスを知ってもらう事が得意です。<br>広告費をかけずに、たくさんの人に認知してもらう事が可能なサービスを提供しています。"
                    />
                    <CardLine number="2"
                              title="動画撮影"
                              text="イベントやPR動画の撮影・企画を行い自社のサービスや商品をより良く見せるサービスを行なっております。"
                    />
                    <CardLine number="3"
                              title="店舗運営"
                              text="ポーランドのオーガニックコスメを販売する「YOPE」の日本初となる店舗を正規代理店として運営しております。"
                    />
                </div>
            </div>

        </div>
        <div style="padding-top: 2000px; padding-bottom: 1000px">
            <div class="position-relative">
                <HeadingA title="お知らせ" title-english="NEWS"/>
            </div>
        </div>
    </main>
</template>
<style lang="scss">
@use "@/assets/scss/modules/variables" as var;

.jumbotron {
    position: relative;
    //aspect-ratio: 16 / 9;
}

.jumbotron-inner {
    width: 100%;
    height: 100%;
}

.jumbotron-img,
.jumbotron-video {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.jumbotron-logo {
    z-index: 2;
    width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.homeNews {
    background-color: var.$base-color;
    padding-bottom: 100px;
    @media (var.$min-md) {
        padding-bottom: 140px;
    }
}

.homeNews-inner {
    max-width: 1000px;
}

.homeNews-link {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 15px;
    border-bottom: 1px solid var.$text-color;
    transition: transform 300ms;
    @media (var.$min-lg) {
        flex-direction: row;
        align-items: center;
    }

    &:hover {
        transform: translateX(-10px)
    }
}

.homeNews-date {
    margin-right: 30px;
    padding: 5px 15px;
    background-color: var.$main-color;
    border-radius: 18px;
    font-size: 1.2rem;
    color: #FFF;
}

.homeNews-item-title {
    padding-top: 15px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 任意の行数を指定 */
    @media (var.$min-lg) {
        padding-top: 0;
    }
}

.cardService {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (var.$min-lg) {
        flex-direction: row;
        justify-content: center;
        align-items: start;
    }
}
</style>